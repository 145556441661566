import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { AuthService } from '../../../../../src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medical-file-info',
  templateUrl: './medical-file-info.component.html',
  styleUrls: ['./medical-file-info.component.scss']
})
export class MedicalFileInfoComponent implements OnInit {
  id: string;
  medicalFile$: Observable<MedicalFile>;

  isCollapsed = false;
  @Input() ModuleName: string;
  @Input() PicSrc: string;

  @Input() SelectedMedicalFile: MedicalFile;
  @Input() UseSelectedFile: boolean = false;


  constructor(
    private authServive: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private medicalFileService: MedicalFileProviderService
  ) { }

  ngOnInit(): void {

    if (!this.UseSelectedFile) {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.id = params.id;
          this.medicalFile$ = this.medicalFileService.getMedicalFile(params.id).pipe(share());
        } else {
          this.id = this.activatedRoute.parent.snapshot.params['id'];
          this.medicalFile$ = this.medicalFileService.getMedicalFile(this.id).pipe(share())
        }
      });
    } else {
      //this.medicalFile$ = of(this.SelectedMedicalFile)
    }


    this.isCollapsed = JSON.parse(localStorage.getItem('user-info-collapsed')) || false;
  }

  handleToggle(): void {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('user-info-collapsed', this.isCollapsed.toString());
  }

  OpenPersonDetailsPage() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה רוצה לעזוב דף זה?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'בטל',
      confirmButtonText: 'כן',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl("/medicalfiles/" + this.id)
      }
    })
  }


  calculateAge(): string {

    let date2: any = new Date()
    let result: string = "-"
    if (this.SelectedMedicalFile == undefined || this.SelectedMedicalFile == null) {
      return "-"
    }
    let date1: any = new Date(this.SelectedMedicalFile.BirthDay)
    const diffInMs = Math.abs(date2 - date1);
    result = (diffInMs / (1000 * 60 * 60 * 24) / 365).toPrecision(3);



    return result
  }



}