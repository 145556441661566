import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { HistoryofOmdanPainComponent } from 'src/app/caretx-shared-module/CaretexModals/historyof-omdan-pain/historyof-omdan-pain.component';
import { MultipleoptionsselectionDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/multipleoptionsselection-dialog/multipleoptionsselection-dialog.component';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { OmdanPainLevels, OmdanPainLocations, OmdanPainMedication, OmdanPainMitigationFactors, OmdanPainNature, OmdanPainSmptoms, OmdanPainTreatment, PaintReport, VasFpsPainExam } from 'src/app/models/OmdanPain';
import { MedicalFile } from 'src/app/models/customer';
import { Drug } from 'src/app/models/drug';
import { Departments, PainLevel, PainLocation, PainMitigatingFactor, PainNature, PainSymptoms, Paintreatment } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';


type Items1 = {
  DepartmentName: string;
  ID: string
};





@Component({
  selector: 'app-departmental-pain-monitoring',
  templateUrl: './departmental-pain-monitoring.component.html',
  styleUrls: ['./departmental-pain-monitoring.component.css']
})
export class DepartmentalPainMonitoringComponent extends CaretexComponent implements OnInit {
  @Output() backToCentralPage = new EventEmitter<void>();

  @Input() IsFromCentralizedPage: boolean = false

  personalId: string
  departments: Departments[] = []
  LoggedInUser: string
  selectedItems1: Items1[] = [];
  IsMedicalFileSelected: boolean = false
  dropdownSettings1 = {};
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  Records:PaintReport[] = []
  PainOmdanForReport: VasFpsPainExam
  IsDepartmentSelected:boolean = false

  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,


    private caretxSetupService: CaretxSetupsProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    public dialog: MatDialog, 
    private authService: AuthService,
    private route: ActivatedRoute,) {
    super(medicalFileService);
    this.LoggedInUser = this.authService.LoggedInUser.UID


    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })
  }






  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    this.IsMedicalFileSelected = false
    this.loadData2("")
    this.doInitialization()
  }




  loadData2(filter: string) {
    this.Records = []
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
    })
  }



  RefreshForm() {
    this.prepareFilterToSearchMedicalFiles()
  }

  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }


  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }

  currentPainOmdan: VasFpsPainExam
  PersonalId:string 
  
  
  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
  }

  ExpandPainReportingWindow:boolean =false
  selectedMedicalFile:MedicalFile = new MedicalFile()
  ExpandReportWindow(record :PaintReport) {
    this.ExpandPainReportingWindow = true
    this.selectedMedicalFile = record.MedicalFileForReport
    this.PainOmdanForReport = record.PainOmdanForReport
  }


  closeExpandedDocument(  record: VasFpsPainExam   ) {
    this.ExpandPainReportingWindow = false
    // Assign it to correct 
    for (let i = 0; i < this.Records.length; i++) {
        if (this.Records[i].MedicalFileForReport.PersonelID == record.PersonelID) {
          this.Records[i].PainOmdanForReport = record
          this.Records[i].PainLevelsTxt = "";
          record.PainLevels.forEach(element => {
            this.Records[i].PainLevelsTxt += ' , ' + element.PainLevel.PainLevel + "\n"
          });
      
          this.Records[i].PainLocationTxt = "";
          record.PainLocations.forEach(element => {
            this.Records[i].PainLocationTxt += ' , ' + element.PainLocation.PainLocation + "\n"
          });
      

          this.Records[i].PainMitigationTxt = "";
          record.MitigationsFactors.forEach(element => {
            this.Records[i].PainMitigationTxt += ' , '+ element.PainMitigatingFactor.PainMitigatingFactor + "\n"
          });
      
          this.Records[i].PainNatureTxt = "";
          record.PainNatures.forEach(element => {
            this.Records[i].PainNatureTxt += ' , ' + element.PainNature.PainNature + "\n"
          });
      
          this.Records[i].PaintreatmentTxt = "";
          record.Paintreatments.forEach(element => {
            this.Records[i].PaintreatmentTxt += ' , ' + element.Paintreatment.Paintreatment + "\n"
          });
      
          this.Records[i].DrugsTxt = "";
          record.OmdanPainMedications.forEach(element => {
            this.Records[i].DrugsTxt += ' , ' + element.Drug.ShortTradeName + "\n"
          });
      
          this.Records[i].PainSymptomsTxt = "";
          record.PainSymptoms.forEach(element => {
            this.Records[i].PainSymptomsTxt += ' , ' + element.PainSymptom.PainSymptoms + "\n"
          });
      
      
        }
    }


  }

  showInfoPerDepartment:boolean = true
  selectedPatient: boolean
  showDepMandatoryLbl: boolean = false


  OpenDepartmentSideMenue() {
    this.selectedPatient = true
    this.showInfoPerDepartment = false
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false

    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.IsDepartmentSelected = false
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
      this.IsDepartmentSelected = false
    }
  
  }

  closeSideNavBar() {
    this.selectedPatient = false

    this.prepareFilterToSearchMedicalFiles()

  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });

      this.Records = []
      this.IsDepartmentSelected = true
      this.filteredData.forEach(element => {
        let tmp :PaintReport  = new  PaintReport()
        element.FullName = element.FirstName + " " + element.LastName
        tmp.MedicalFileForReport = element
        tmp.PainOmdanForReport = new VasFpsPainExam()
        tmp.PainOmdanForReport.Status = "A"
        tmp.PainOmdanForReport.OmdanDate =  tmp.PainOmdanForReport.CreatedAt =  new Date()
        tmp.PainOmdanForReport.UpdatedById = this.authService.LoggedInUser.UID
        tmp.PainOmdanForReport.UpdatedBy = this.authService.LoggedInUser
        tmp.PainOmdanForReport.PersonelID =  tmp.MedicalFileForReport.PersonelID
        tmp.PainOmdanForReport.TenantID = this.authService.LoggedInTenantID
        this.Records.push(tmp)
      });
      
   
    }
  
  }


  OpenHistoryForm(SelectedFile: MedicalFile) {
    const dialogRef = this.dialog.open(HistoryofOmdanPainComponent, {
      data: {
        SelectedFile: SelectedFile,
      }
    });
  }


}

