import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MedicalFile } from '../models/customer';
import { MedicalFileProviderService } from '../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Departments } from '../models/generalhousedsetups';
import { AuthService } from '../services/auth.service';
import { CaretxSetupsProviderService } from '../caretx-setups-module/setup-services/setups.data-provider.service';
import { BridgeEnums, OptionObject } from '../services/enums';
import { CardixDataProviderService } from './CardixServices/cardix.data-provider.service';
import { CardixTask, HistoricCardixTask, ReportCardixTask } from '../models/Cardix';
import { ToasterService } from '../services/toastr.service';
import { CaretexTextareaDialogComponent } from '../caretx-shared-module/CaretexModals/caretex-textarea-dialog/caretex-textarea-dialog.component';
import { CaretxSharedModulesProviderService } from '../caretx-shared-module/sharedmodule.data-provider.services';
import { Drug } from '../models/drug';
import { ReportingService } from '../services/reporting.service';
import jsPDF from 'jspdf';
import { RowInput } from 'jspdf-autotable';
import Swal from 'sweetalert2';
import { PainMeasureDialogComponent } from '../caretx-shared-module/CaretexModals/pain-measure-dialog/pain-measure-dialog.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';


type Items1 = {
  DepartmentName: string;
  ID: string
};


@Component({
  selector: 'app-caretx-cardix',
  templateUrl: './caretx-cardix.component.html',
  styleUrls: ['./caretx-cardix.component.scss']
})

export class CaretxCardixComponent implements OnInit {
  DefaultPhotoURL: string = "../../assets/images/user-avatar.png"
  TabId = "main"
  constructor(public medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private bridgeEnum: BridgeEnums,
    private datePipe: DatePipe,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private cardixProviderService: CardixDataProviderService,
    private authService: AuthService,
    public dialog: MatDialog,
    private reportingService: ReportingService,
    private toastrService: ToasterService,) {

    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })

    this.caretxSharedlService.getMedicalDrugs("").subscribe(
      data => this.drugs = data,
      err => {
        this.drugs = []
      }
    )
    this.selectedMedicalFile = new MedicalFile()
    this.selectedMedicalFile.FirstName = "עדיין לא נבחר"
    this.selectedMedicalFile.LastName = "."
    this.selectedMedicalFile.PersonelID = "-"
    this.selectedMedicalFile.ImagePath = "../../assets/images/user-avatar.png"
    this.selectedMedicalFile.Department = new Departments()
    this.selectedMedicalFile.Department.DepartmentName = "עדיין לא נבחר"
    this.startDate = new Date()
    this.endDate = new Date()
    this.DrugDosageForms = bridgeEnum.DrugDosageForm

    this.EndTime.setHours(this.StartTime.getHours() + 4)
  }
  startDate: Date
  endDate: Date
  StartTime: Date = new Date()
  EndTime: Date = new Date()
  selectedFilter: boolean
  selectedPatient: boolean
  reportsFilter: boolean
  disableparentwindow: boolean = false
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  departments: Departments[] = []
  selectedMedicalFile: MedicalFile
  startDateText: string = "-"
  endDateText: string = "-"
  DrugDosageForms: OptionObject[]
  selectedDosageForm: string
  ctxEvents: CardixTask[] = []
  reportCardixRecords: ReportCardixTask[] = []

  ctxHistoricEvents: HistoricCardixTask[] = []
  tab1: boolean = true
  tab2: boolean = false
  tab3: boolean = false
  selectedItems1: Items1[] = [];
  dropdownSettings1 = {};
  pageType: string = "Operation"
  drugs: Drug[] = []
  selectedItems2 = [];
  dropdownSettings2 = {};
  selectedItems3 = [];
  dropdownSettings3 = {};
  selectedHMO: string
  patientFilter: string = ""
  medicalDrugsFilter: string = ""
  showDepMandatoryLbl: boolean = false
  unResolvedctxEvents: CardixTask[] = []
  @ViewChild("todate") todate :ElementRef;


  tab1StartTime: Date
  tab1StartDate: Date


  tab2StartTime: Date
  tab2StartDate: Date

  tab3StartTime: Date
  tab3StartDate: Date



  ngOnInit(): void {

    this.doInitialization()
  }

  doInitialization() {

    this.loadData2("")
    this.startDate = new Date()
    this.StartTime.setHours(new Date().getHours() - 1); // start now - 1 hour
    this.endDate = new Date()
    this.EndTime.setHours(23, 59, 59, 0);
    this.startDateText = "מיום " + this.convertDayToHebrewName((new Date(this.startDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.startDate) + " משעה:" + this.formatDateToHHmm(this.StartTime)
    this.endDateText = "עד יום " + this.convertDayToHebrewName((new Date(this.endDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.endDate) + " עד שעה:" + this.formatDateToHHmm(this.EndTime)

    this.tab1StartDate = this.tab2StartDate = this.tab3StartDate = this.startDate
    this.tab1StartTime = this.tab2StartTime = this.tab3StartTime = this.StartTime
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownSettings2 = {
      singleSelection: false,
      idField: "id",
      textField: "ShortTradeName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }


    this.dropdownSettings3 = {
      singleSelection: false,
      idField: "PersonelID",
      textField: "FullName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }



  }

  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.filteredData.forEach(element => {
        element.FullName = element.FirstName + " " + element.LastName
      });
      this.totalNumOfRecords = this.allFiles.length
    })
  }

  OpenSideNav() {
    this.selectedPatient = true
  }

  OpenSideNavDepartment() {
    this.selectedFilter = true
  }


  filterPaitents($event) {
    let filter: string = $event.currentTarget.value
    this.filteredData = this.allFiles
    this.allFiles = this.medicalFileService.data
    this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {
      const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName).toLowerCase();
      return searchStr.indexOf(filter.toLowerCase()) !== -1;
    });
  }

  AddFilter() {
    if (this.endDate.valueOf() < this.startDate.valueOf() ) {
      Swal.fire({
        title: 'לידיעתך',
        text: "תאריך עד לא יכול להיות לפני תאריך מ",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      }).then((result) => {
 
      })
    }else {
      this.StartTime.setDate((new Date(this.startDate)).getDate())
      this.StartTime.setFullYear((new Date(this.startDate)).getFullYear())
      this.StartTime.setMonth((new Date(this.startDate)).getMonth())
      this.EndTime.setDate((new Date(this.endDate)).getDate())
      this.EndTime.setFullYear((new Date(this.endDate)).getFullYear())
      this.EndTime.setMonth((new Date(this.endDate)).getMonth())
  
      this.selectedFilter = false
      this.startDateText = "מיום " + this.convertDayToHebrewName((new Date(this.startDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.startDate) + " משעה:" + this.formatDateToHHmm(this.StartTime)
      this.endDateText = "עד יום " + this.convertDayToHebrewName((new Date(this.endDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.endDate) + " עד שעה:" + this.formatDateToHHmm(this.EndTime)
      this.GetAllMedicinesTaskForCurrentMonth()
      this.GetAllUnresolvedMedicinesTask()
    }
  }


  checkEndDateForFilter($event) {

    let selectedEndDate = new Date($event)

    if (selectedEndDate.valueOf() < this.startDate.valueOf() ) {
      Swal.fire({
        title: 'לידיעתך',
        text: "תאריך עד לא יכול להיות לפני תאריך מ",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      }).then((result) => {
        this.todate.nativeElement.value = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.endDate =   new Date( ); 
      })
    }else {
      this.endDate = $event
    }

  }

  closeSideNavePatient(medicalFile: MedicalFile) {
    this.selectedMedicalFile = medicalFile
    this.selectedPatient = false
    this.AddFilter()
  }

  updateTimeField(field, $event) {
    var newarr = $event.split(":");
    let x1: number
    let x2: number

    switch (field) {
      case "StartTime":
        x1 = new Date(this.startDate).setHours(newarr[0])
        this.StartTime = new Date(x1)
        x2 = new Date(this.StartTime).setMinutes(newarr[1])
        this.StartTime = new Date(x2)
        break
      case "EndTime":
        x1 = new Date(this.endDate).setHours(newarr[0])
        this.EndTime = new Date(x1)
        x2 = new Date(this.EndTime).setMinutes(newarr[1])
        this.EndTime = new Date(x2)
        break
      default:
        break
    }
  }

  GetAllMedicinesTaskForCurrentMonth() {
    this.cardixProviderService.getMediciensTasks(this.selectedMedicalFile.PersonelID, "", this.StartTime, this.EndTime, "","asc").subscribe(
      data => {
        if (data.length) {
          this.ctxEvents = data

        } else {
          this.ctxEvents = [];
        }
      },
      err => {
        this.ctxEvents = []
      }
    )

  }

  GetAllUnresolvedMedicinesTask() {
    // Get All Opened Tasks that their due date passed already more than 1 hour ago 
    let startTime2: Date
    let endTime2: Date = new Date()
    startTime2 = this.StartTime
    startTime2.setFullYear(startTime2.getFullYear() - 1);
    endTime2.setHours(endTime2.getHours() - 1);
    this.cardixProviderService.getMediciensTasks(this.selectedMedicalFile.PersonelID, "", startTime2, endTime2, "","desc").subscribe(
      data => {
        if (data.length) {
          this.unResolvedctxEvents = data

        } else {
          this.unResolvedctxEvents = [];
        }
      },
      err => {
        this.unResolvedctxEvents = []
      }
    )
  }


  taskAction(task: CardixTask, action: string) {
    //common-update
    let fromTime: Date = new Date()
    let toTime: Date = new Date()
    fromTime.setHours(new Date().getHours() - 1)
    toTime.setHours(new Date().getHours() + 1)

    if ((new Date(task.DueDate) >= fromTime) && (new Date(task.DueDate) <= toTime)) {
      task.CompletedAt = new Date();
      task.CompletedById = this.authService.LoggedInUser.UID;
      if (action === 'rejected') {
        this.ShowUserTextDialog(task)
      }
      if (action === 'approved') {
        task.Status = "P";

        // Check if Medication of Pain
        if (task.Instruction.Drug.Cb2) {
          this.showPainMeasureDialog(task)
        } else {
          task.Comment = "";
          this.DoUpdate(task);
        }
      }
    } else if (new Date(task.DueDate) > toTime) {
      Swal.fire({
        title: 'לידיעתך',
        text: "אתה לא יכול לעדכן הוראה שתאריך / זמן הגשה שלה בעתיד!",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      })
    } else {

      if (action === 'lateapproval') {
        this.ShowLateApprovalDialog(task)
      }
      if (action === 'rejected') {
        this.ShowUserTextDialog(task)
      }

    }
  }

  showPainMeasureDialog(task: CardixTask) {
    const dialogRef = this.dialog.open(PainMeasureDialogComponent, {
      data: {

      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "" && result != undefined) {
        task.PainMeasure = result
      }
      task.CompletedById = this.authService.LoggedInUser.UID
      task.CompletedAt = new Date()
      task.Comment = "";
      this.DoUpdate(task);
    });
  }

  DoUpdate(task: CardixTask) {
    this.cardixProviderService.updateCardixTask(task).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg);
        this.GetAllMedicinesTaskForCurrentMonth()
        this.GetAllUnresolvedMedicinesTask()
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  ShowUserTextDialog(task: CardixTask) {
    // Need to be changed 
    const dialogRef = this.dialog.open(CaretexTextareaDialogComponent, {
      data: {
        Disabled: task.Status == 'R',
        UserText: task.Comment,
        Title: "סיבת דחית/אי ביצוע הוראה",
        TextFieldName: "סיבת דחיה/אי ביצוע"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (task.Status == 'A') {
        task.Status = "R"
        task.Comment = result
        task.CompletedAt = new Date()
        task.CompletedById = this.authService.LoggedInUser.UID
        this.DoUpdate(task)
      }
    });
  }

  ShowLateApprovalDialog(task: CardixTask) {
    // Need to be changed 
    const dialogRef = this.dialog.open(CaretexTextareaDialogComponent, {
      data: {
        Disabled: task.Status == 'L', // Late Approval
        UserText: task.Comment,
        Title: "סיבת איחור ביצוע מתן",
        TextFieldName: "סיבת איחור ביצוע מתן"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (task.Status == 'A') {
        task.Status = "L"
        task.Comment = result
        task.CompletedAt = new Date()
        task.CompletedById = this.authService.LoggedInUser.UID
        this.DoUpdate(task)
      }
    });
  }

  openComment(task) {
    this.ShowUserTextDialog(task)
  }

  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
    this.prepareFilterToSearchMedicalFiles()
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
    }
    this.prepareFilterToSearchMedicalFiles()
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false
    this.filteredData = this.allFiles
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.prepareFilterToSearchMedicalFiles()
  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });
    }

  }

  OpenReportPage() {
    this.pageType = "Reports"
  }

  OpenOperationPage() {
    this.pageType = "Operation"
  }

  OpenSideNavReportsFilter() {
    this.reportsFilter = true
  }

  AddReportFilter() {

    // Check Mandatory Fields
    if (this.selectedItems1.length == 0) {
      this.showDepMandatoryLbl = true
    } else {
      this.reportsFilter = false
      this.StartTime.setDate((new Date(this.startDate)).getDate())
      this.StartTime.setFullYear((new Date(this.startDate)).getFullYear())
      this.StartTime.setMonth((new Date(this.startDate)).getMonth())
      this.EndTime.setDate((new Date(this.endDate)).getDate())
      this.EndTime.setFullYear((new Date(this.endDate)).getFullYear())
      this.EndTime.setMonth((new Date(this.endDate)).getMonth())


      if (this.selectedDosageForm == undefined) {
        this.selectedDosageForm = ""
      }

      if (this.selectedHMO == undefined) {
        this.selectedHMO = ""
      }

      this.cardixProviderService.getHistoricalCardixTasks(this.StartTime, this.EndTime, this.selectedItems3, this.selectedItems2, this.selectedDosageForm, this.selectedHMO).subscribe(
        data => {
          if (data.length) {
            this.reportCardixRecords = data

          } else {
            this.reportCardixRecords = [];
          }
        },
        err => {
          this.reportCardixRecords = []
        }
      )

    }







  }

  onSelectingAllDrugs($event) {
  }

  onDeSelectAllDrugs($event) {
  }

  onDrugSelect($event) {
  }

  onDrugDeSelect($event) {

  }

  onSelectingAllPaitent($event) {
  }

  onDeSelectAllPatient($event) {

    this.patientFilter = ""
  }

  onPatientSelect($event) {

  }

  onPatientDeSelect($event) {

  }

  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any

  printReport() {


    let StartTime = this.reportingService.formatDateToMMDDYYY(this.startDate) + " " + this.reportingService.formatDateToHHmm(this.startDate)
    let EndTime = this.reportingService.formatDateToMMDDYYY(this.endDate) + " " + this.reportingService.formatDateToHHmm(this.endDate)

    let departmentsTxt = this.selectedItems1.map(x => x.DepartmentName).join(', ');

    let reportTitle = "דו\"\ח מתן תרופות"
    this.showGeneratedPdf = true
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle: number = 118
    lastYPosition = this.reportingService.SetHeaderForCardixGeneric(doc, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
    this.reportingService.setFooter3(doc, this.authService.LoggedInUser, new Date())


    var label = ""
    //lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, label, "אבחנות רפואיות:", new User() , this.authService.LoggedInUser , new Date() , new Date(), this.medicalFile1,reportTitle,xPosTitle)

    if (this.reportCardixRecords.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection2(doc, lastYPosition, "לא נמצאו תוצאות", this.authService.LoggedInUser, new Date(), reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
    } else {
      var colHeader1: RowInput[] = [
        [this.reportingService.reverseString("כמה פעמים ניתנה"),
        this.reportingService.reverseString("תרופה"),
        this.reportingService.reverseString("ת.ז"),
        this.reportingService.reverseString("מטופל"),
        ]]
      var colVals1: RowInput[] = []
      this.reportCardixRecords.forEach(record => {

        let tmpValRow: RowInput =
          [
            this.reportingService.reverseString(record.NumOfRecords.toString()),
            this.reportingService.reverseString(record.ShortTradeName),
            this.reportingService.reverseString(record.PersonelID),
            this.reportingService.reverseString(record.FirstName + " " + record.LastName),
          ]


        colVals1.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddGenericTableToPDF(doc, lastYPosition, this.authService.LoggedInUser, colHeader1, colVals1, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime, new Date())

    }

    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }

  //***************************Help Functions ********************** */
  EnableTabByName(tabName: string) {
    if (this.tab1) {
      this.tab1StartDate = this.startDate
      this.tab1StartTime = this.StartTime
    }

    if (this.tab2) {
      this.tab2StartDate = this.startDate
      this.tab2StartTime = this.StartTime
    }
    if (this.tab3) {
      this.tab3StartDate = this.startDate
      this.tab3StartTime = this.StartTime
    }

    switch (tabName) {
      case "tab1":
        this.tab1 = true
        this.tab2 = false
        this.tab3 = false
        break
      case "tab2":
        this.tab1 = false
        this.tab2 = true
        this.tab3 = false
        break
      case "tab3":
        this.tab1 = false
        this.tab2 = false
        this.tab3 = true
        this.cardixProviderService.getCompletedMediciensTasks(this.selectedMedicalFile.PersonelID, "", this.StartTime, this.EndTime, "").subscribe(
          data => {
            if (data.length) {
              this.ctxHistoricEvents = data

            } else {
              this.ctxHistoricEvents = [];
            }
          },
          err => {
            this.ctxHistoricEvents = []
          }
        )

        break
    }

    this.resetDatePerTab()
  }

  resetDatePerTab() {
    if (this.tab1) {
      this.startDate = this.tab1StartDate
      this.StartTime = this.tab1StartTime
    }

    if (this.tab2) {
      this.startDate = this.tab2StartDate
      this.StartTime = this.tab2StartTime
    }

    if (this.tab3) {
      this.startDate = this.tab3StartDate
      this.StartTime = this.tab3StartTime
    }

    this.startDateText = "מיום " + this.convertDayToHebrewName((new Date(this.startDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.startDate) + " משעה:" + this.formatDateToHHmm(this.StartTime)
    this.endDateText = "עד יום " + this.convertDayToHebrewName((new Date(this.endDate)).getDay() + 1) + " " + this.formatDateToMMDDYYY(this.endDate) + " עד שעה:" + this.formatDateToHHmm(this.EndTime)

  }

  convertDayToHebrewName(dayNum: number) {
    switch (dayNum) {
      case 1: return "ראשון"
        break;
      case 2: return "שני"
        break;
      case 3: return "שלישי"
        break
      case 4: return "רביעי"
        break
      case 5: return "חמישי"
        break
      case 6: return "שישי"
        break
      case 7: return "שבת"
        break
    }
  }

  formatDateToMMDDYYY(dateToFormat: Date) {
    let tmpDate = new Date(dateToFormat)
    var dd = tmpDate.getDate();
    var mm = tmpDate.getMonth() + 1;
    var yyyy = tmpDate.getFullYear();
    return (mm < 10 ? '0' + mm.toString() : mm.toString()) + '/' + (dd < 10 ? '0' + dd.toString() : dd.toString()) + '/' + yyyy;
  }

  formatDateToHHmm(dateToFormat: Date) {
    let tmpDate = new Date(dateToFormat)
    var hh = tmpDate.getHours();
    var mm = tmpDate.getMinutes();
    return (hh < 10 ? '0' + hh.toString() : hh.toString()) + ':' + (mm < 10 ? '0' + mm.toString() : mm.toString());
  }


}

