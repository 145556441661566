import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-caretex-list-search-bar',
  templateUrl: './caretex-list-search-bar.component.html',
  styleUrls: ['./caretex-list-search-bar.component.scss']
})
export class CaretexListSearchBarComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() RecordStatus: Map<string, string>
  @Input() TotalNumberFound: number = 0
  @Output() DoSearch = new EventEmitter<string>();

  @Input() SearchFormGroup: FormGroup
  @Input() FormData: any[]
  test: string = "picker4"
  formControlsValues: Map<number, any[]> = new Map<number, any[]>();
  formControlsTypes: Map<string, string> = new Map<string, string>();

  interim_payment_multi = [1, 2, 3];
  interim_payment_0 = "pass1"
  interim_payment_1 = "pass2"
  interim_payment_2 = "pass3"
  i = 0
  ii: string = "datepicket111"
  constructor() { }

  ngOnInit(): void {

    for (var key in this.FormData) {
      this.formControlsTypes[this.FormData[key]["formcontrolname"]] = this.FormData[key]["type"]

      if (this.FormData[key]["type"] == "multi") {
        if (this.FormData[key]["value"] != "" && this.FormData[key]["value"] != undefined) {
         
            let obj = JSON.parse(this.FormData[key]["value"])
            this.formControlsValues[key] = obj
       

        }
      }
    }
  }

  ConvertValueToObjects(row: any) {

    return JSON.parse(row["value"]);
  }

  addDays(numOfDays: number, date: Date) {
    date.setDate(date.getDate() + numOfDays);

    return date;
  }


  formatDate(date: Date) {
    const year = date.toLocaleString('default', { year: 'numeric' });
    const month = date.toLocaleString('default', { month: '2-digit' });
    const day = date.toLocaleString('default', { day: '2-digit' });

    return [year, month, day].join('-');
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.Search()
    }
  }

  Search() {
    let whereCond: string[] = []
    let whereCond1: string
    let whereCond2: string
    let whereCond3: string


    if (this.SearchFormGroup.value.created_at_from != undefined && this.SearchFormGroup.value.created_at_from != "") {
      whereCond2 = "created_at >= '" + this.formatDate(this.SearchFormGroup.value.created_at_from) + "' "
      if (this.SearchFormGroup.value.created_at_to != "") {
        let ToDate = this.addDays(1, this.SearchFormGroup.value.created_at_to)
        whereCond2 += " and created_at < '" + this.formatDate(ToDate) + "' "
      }
      whereCond.unshift(whereCond2)

    }

    if (this.SearchFormGroup.value.closed_at_from != undefined && this.SearchFormGroup.value.closed_at_from != "") {
      whereCond3 = "closed_at >= '" + this.formatDate(this.SearchFormGroup.value.closed_at_from) + "' "
      if (this.SearchFormGroup.value.created_at_to != "") {
        let ToDate = this.addDays(1, this.SearchFormGroup.value.closed_at_to)
        whereCond3 += " and closed_at < '" + this.formatDate(ToDate) + "' "
      }
      whereCond.unshift(whereCond3)

    }

    // Custom Fields 

    Object.keys(this.SearchFormGroup.controls).forEach((key: string) => {

      if (this.SearchFormGroup.value[key] != "" && this.SearchFormGroup.value[key] != undefined && this.SearchFormGroup.value[key] != "") {

        if (this.formControlsTypes[key] == "multi") {
         
          whereCond1 = '\'' + this.SearchFormGroup.value[key].join('\',\'') + '\''
          whereCond1 = key + " IN (" + whereCond1 + ")"
      
        }
        else if (this.formControlsTypes[key] == "txtinput") {

          whereCond1 = this.SearchFormGroup.value[key]

          whereCond1 = key + " like '%25" + whereCond1 + "%25'"
        }
        whereCond.unshift(whereCond1)

      }

    })

    let filter = ""
    if (whereCond.length > 0) {
      filter = whereCond.join(" and ")
    }

    this.DoSearch.emit(filter)

  }


  Clear() {
    this.SearchFormGroup.reset()
  //  this.DoSearch.emit()
  }
}
