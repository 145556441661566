import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { DrugInstruction } from '../../../../../../../src/app/models/drugInstruction';
import { AuthService } from '../../../../../../../src/app/services/auth.service';
import { BridgeEnums, DialogResult, InstrStatus } from '../../../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { DrugInstructionActionDialogComponent } from '../../../../../../../src/app/caretx-shared-module/CaretexModals/drug-instruction-action-dialog/drug-instruction-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MedicationInstructionsDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/medication-instructions-dialog/medication-instructions-dialog.component';

@Component({
  selector: 'app-regularmedications',
  templateUrl: './regularmedications.component.html',
  styleUrls: ['./regularmedications.component.css']
})
export class RegularmedicationsComponent implements OnInit {

  @Input() Title: string
  @Input() MedicationTypes: string
  @Input() DrugInstructionList: DrugInstruction[]
  @Output() selectedDrugInstr = new EventEmitter<number>();
  @Input() ShowActions: boolean = true
  @Input() GrayHeader: boolean = false
  @Input()  ShowForRelease : boolean = false

  @Input()  LimitTableHeight : boolean = false


  @Output() selectedInstrForRelease = new EventEmitter<DrugInstruction[]>();


  id: string
  //newDrugInstruction: DrugInstruction
  showList: boolean = true
  currentIndex: number
  instrstatus: InstrStatus
  currentRowIndex: number
  popUpWindowTitle: string
  statusModal: string = ""
  modalTarget: string = ""
  constructor(private _title: Title,
    private bridgeEnum: BridgeEnums,
    private authServive: AuthService,

    public dialog: MatDialog,
    private toastrService: ToasterService,
    private caretxMedicalService: CaretxMedicalProviderService,
    private route: ActivatedRoute,
    private _ar: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._title.setTitle('Caretx| קבלה רפואית');
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
    //Sort by created date
    this.DrugInstructionList.sort((a: DrugInstruction, b: DrugInstruction) => {
      return +new Date(b.CreatedAt) - +new Date(a.CreatedAt);
    });

  }



  newRegularMidicationPage() {
    this.showList = false;
    this.selectedDrugInstr.emit(undefined)

  }

  handleregularMedicationWindowEvents($event) {
    this.showList = $event
  }

  BuildRateStringToDisplay(drugInst: DrugInstruction): string {
    let text: string = ''
    let drugFreqUomDescr: string = this.bridgeEnum.getName(drugInst.frequencyUom, this.bridgeEnum.DrugFreqUoms)
    let drugFreq: string = this.bridgeEnum.getName(drugInst.frequency, this.bridgeEnum.DrugFreqs)

    switch (drugInst.frequencyUom) {
      case "D":
        if (drugInst.daysToSkip == undefined)
          text = drugFreq + " " + drugFreqUomDescr + " כל יום "
        else
          text = drugFreq + " " + drugFreqUomDescr + " כל " + drugInst.daysToSkip + "ימים"
        break

      case "P":
        if (drugInst.maxInday == undefined)
          text = "לפי צורך"
        else
          text = " לפי צורך מקסימום " + drugInst.maxInday + " ביום"
        break
      default:
        break
    }

    return text

  }

  doctorNotes(DrugInstr: DrugInstruction) {
    if (DrugInstr.doctorNotes == "" || DrugInstr.doctorNotes == null || DrugInstr.doctorNotes == undefined)
      return "אין"
    else
      return DrugInstr.doctorNotes
  }

  openDrugInstruction(index) {
    this.selectedDrugInstr.emit(index)
  }




  deleteRow(i) {
    this.currentRowIndex = i
    if ((this.DrugInstructionList[i].instrStatus == "D")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק הוראה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteDrugInstr(this.DrugInstructionList[this.currentRowIndex])
        }
      })

    }
  }

  deleteDrugInstr(record: DrugInstruction) {
    let filter = '(id=' + '\'' + record.ID + '\'' + ')'
    this.caretxMedicalService.deletetDrugInstr(this.id, filter).subscribe({
      next: data => {
        // this.getDrugInstrFromDB("")
        this.DrugInstructionList.splice(this.currentRowIndex, 1)
        this.toastrService.Sucesss("", "הרשומה נמחקה")
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }






  OnActivatelBtnClick($event, drugInst) {
    if (drugInst.instrStatus == InstrStatus.Active) {
      const dialogRef = this.dialog.open(DrugInstructionActionDialogComponent, {
        data: {
          ActionTitle: "סיבת אתחול הוראה מחדש",
          IntsrStatus: InstrStatus.Active,
          DrugInstr: drugInst
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getDrugInstrFromDB("")
      });
    }
  }



  OnCancelBtnClick($event, drugInst) {
    if (drugInst.instrStatus == InstrStatus.Active) {
      const dialogRef = this.dialog.open(DrugInstructionActionDialogComponent, {
        data: {
          ActionTitle: "סיבת ביטול",
          IntsrStatus: InstrStatus.Cancelled,
          DrugInstr: drugInst
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getDrugInstrFromDB("")
      });
    }
  }

  OnStopBtnClick($event, drugInst) {
    if (drugInst.instrStatus == InstrStatus.Active) {
      const dialogRef = this.dialog.open(DrugInstructionActionDialogComponent, {
        data: {
          ActionTitle: "סיבת הפסקת/ביטול הוראה",
          IntsrStatus: InstrStatus.Cancelled,
          DrugInstr: drugInst
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getDrugInstrFromDB("")
      });
    }
  }

  OnsuspendBtnClick($event, drugInst) {
    if (drugInst.instrStatus == InstrStatus.Active) {
      const dialogRef = this.dialog.open(DrugInstructionActionDialogComponent, {
        data: {
          ActionTitle: "סיבת השהיית הוראה",
          IntsrStatus: InstrStatus.Stopped,
          DrugInstr: drugInst
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //this.getDrugInstrFromDB("")
      });
    }
  }



  commentModalHander($event) {
    let dialogResult: DialogResult = $event.result
    let comment = $event.comment

    switch (dialogResult) {
      case DialogResult.OK:
        this.doUpdateInstrStatusAction(comment, this.instrstatus)
        break;
      case DialogResult.Cancel:
        break;
      default:
        break
    }
  }

  doUpdateInstrStatusAction(comment: string, status: InstrStatus) {
    this.DrugInstructionList[this.currentIndex].instrStatus = this.instrstatus
    this.DrugInstructionList[this.currentIndex].StatusChangeReason = comment
    this.DrugInstructionList[this.currentIndex].Modified = true

    this.caretxMedicalService.updateDrugInstr(this.DrugInstructionList[this.currentIndex]).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }



  selectFromList() {
    const dialogRef = this.dialog.open(MedicationInstructionsDialogComponent, {
      data: {

        id: this.id,
        status: ""
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
     


    let  selectedDrugInstructionList: DrugInstruction[] = []

    result.forEach(element => {
      if ((element as DrugInstruction).AllowOnRelease ) {
        selectedDrugInstructionList.push(element)
      }
        
      });

      this.selectedInstrForRelease.emit(selectedDrugInstructionList)
    });


  }

}

